
import {throwError as observableThrowError,  Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { LoggingService } from './logging.service';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { ICompanyInfo, IEmitChannel } from '../interfaces/configuration.interface';
import swal2 from 'sweetalert2';

export enum UploadType {
  STOPS = 'stopsarchive',
  GTFS = 'gtfs',
  TIMETABLES = 'timetables',
  APK = 'apk',
  CONTROLLER_APK = 'controllerApk',
  TEMPLATE = 'template'
}

export enum UploadResult {
  OK = 'OK',
  ERROR = 'ERROR'
}

export enum SubmitActionType {
  CREATE = 'CREATE',
  EDIT = 'EDIT'
}

export interface AppInfo {
  version: string;
  forced: boolean;
  enabled: boolean;
}

@Injectable()
export class ListService {
  listRouting: string;  // url su browser
  restURL: string;   // url servizio da chiamare
  backToList: boolean;   // se ha un 'torna indietro' da gestire
  selectedCompany: number; // id compagnia già selezionata

  paging: boolean;   // se serve lista paginata (lista)
  page: number;  // numero di pagina da visualizzare (lista)
  rows: number;  // numero righe per ogni pagina (lista)
  orderBy: string;  // ordinamento (lista)
  totalRows: number;  // numero totale righe associate alla chiamata paginata (lista)
  visibleFilter: boolean  // indica se deve far vedere il pulsante filtro o meno

  selectedID: number;  // id della chiamata a dettaglio elemento

  // NON SERVONO
  idSupplierFilter: number;
  idCommissionFilter: number;
  idOrderFilter: number;

  partners3rd: IEmitChannel[];
  fileConfigurations: ICompanyInfo[];

  constructor(private http: HttpClient, private logger: LoggingService, private router: Router) {
    this.paging = true;  //  default value for paging
    this.page = 1;  //  default value for page
    this.rows = environment.rows;  //  environment.ts file
    this.backToList = false;
    this.totalRows = 0;
    this.visibleFilter = false;
  }

   // VALIDO PER PRATICKO

  configurationServiceCall(route, backToList, paging, page, rows) {
    this.listRouting = route;
    this.backToList = false;
    this.paging = paging;
    this.page = page;
    this.rows = rows;
  }

  changePage(page) {
    this.page = page;
  }

  resetList() {
    this.listRouting = '';
    this.restURL = '';
    this.orderBy = null;
    this.totalRows = null;
    this.selectedID = null;
    this.backToList = false;
    this.visibleFilter = false;
    this.selectedCompany = null;
  }

  resetListAndCallService(path) {
    this.resetList();
    this.restURL = path;
  }

  getListFilter(filters?: any, replaceParam?: boolean, paging: boolean = true) {
    let queryStr = this.setListFilterQueryStr(filters, false, false, paging);

    if (replaceParam) {
      queryStr = queryStr.replace('&', '?');
    }

    // chiamata HTTP
    return this.http.get(queryStr).pipe(
    map(
      (response: Response) => {
        let res: any = response;
        this.manageResponse(res)
        return res;
      }
    ), catchError(
      (error: Response) => {
        let err: any = error;
        if (this.manageErrors(err)) {
          return observableThrowError(err.json);
        }
      }
    ));
  }

  getListFilterLine(filters: any) {
    const queryStr = this.setListFilterQueryStr(filters, false, true);

    // chiamata HTTP
    return this.http.get(queryStr).pipe(
    map(
      (response: Response) => {
        let res: any = response;
        this.manageResponse(res)
        return res;
      }
    ), catchError(
      (error: Response) => {
        let err: any = error;
        if (this.manageErrors(err)) {
          return observableThrowError(err.json);
        }
      }
    ));
  }

  getListSelect(path) {
     // stringa per chiamata da fare
    const queryStr = environment.restBaseUrl + path;
     // chiamata HTTP
    return this.http.get(queryStr).pipe(
    map(
      (response: Response) => {
        let res: any = response;
        return res;
      }
    ), catchError(
      (error: Response) => {
        let err: any = error;
        if (this.manageErrors(err)) {
          return observableThrowError(err.json);
        }
      }
    ), );
  }

  exportListCsv(filters, pathUrl): Observable<any> {
    const queryStr = this.setListFilterQueryStr(filters, true);

    return this.getCsv(`${pathUrl}${queryStr}`);
  }

  exportListPdf(filters, pathUrl): Observable<any> {
    const queryStr = this.setListFilterQueryStr(filters, true);

    return this.getDoc(`${pathUrl}${queryStr}`, null, true);
  }

  getSingleObject(path) {
    const queryStr = environment.restBaseUrl + path;
     // chiamata HTTP
    return this.http.get(queryStr).pipe(
    map(
      (response: Response) => {
        let res: any = response;
        //  this.manageResponse(res)
     this.  manageResponseNotPaging(res)
        return res;
      }
    ), catchError(
      (error: Response) => {
        let err: any = error;
        if (this.manageErrors(err)) {
          return observableThrowError(err.json);
        }
      }
    ));
  }

  getFilterElements(fileName) {
    return this.http.get('assets/data/' + fileName + '.json').pipe(
      map((res: Response) => res.json())
    );  // records in this case
  }

  delete(pathUrl) {
    let queryStr = environment.restBaseUrl;
     return this.http.delete(queryStr + pathUrl).pipe(
     map(
      (response: Response) => {
        let res: any = response;
        this.manageResponse(res)
        return res;
      }
    ), catchError(
      (error: Response) => {
        let err: any = error;
        if (this.manageErrors(err)) {
          return observableThrowError(err.json);
        }
      }
    ));
  }

  deleteWithQuery(pathUrl , dataObject: Object) {
    let queryStr = environment.restBaseUrl;
     return this.http.delete(queryStr + pathUrl , dataObject).pipe(
     map(
      (response: Response) => {
        let res: any = response;
        this.manageResponse(res)
        return res;
      }
    ), catchError(
      (error: Response) => {
        let err: any = error;
        if (this.manageErrors(err)) {
          return observableThrowError(err.json);
        }
      }
    ));
  }

  edit(dataObject: Object, pathUrl): Observable<any> {

    return this.http.put(environment.restBaseUrl + pathUrl, dataObject).pipe(
    map(
      (response: Response) => {
        let res: any = response;
        this.manageResponse(res)
        return res;
      }
    ), catchError(
      (error: Response) => {
        let err: any = error;
        if (this.manageErrors(err)) {
          return observableThrowError(err.json);
        }
      }
    ));
  }

  getObject(pathUrl: string) {
    const queryStr = environment.restBaseUrl + pathUrl;

    return this.http.get<any>(queryStr).pipe(
      map(
      (response: Response) => {
        let res: any = response;

        if (res.outcome.success === true) {
          this.logger.log('List getResult:', ' SUCCESS', 200);
          return res;
        } else {
          this.logger.log('List getResult:', ' FAILURE', 200);
          return res;

        }
      }
      ),
      catchError(
      (error: Response) => {
        let res: any = error;
        if (res.outcome.code === '0005' || res.outcome.code === '0007') {
          this.sessionExpired();
        } else {
          return observableThrowError(error);
        }
      }
      ),
    );
  }

  /** GET call specifically configured to download and manage a stored file */
  downloadObject(pathUrl: string, fileType?: string) {
    const queryStr = environment.restBaseUrl + pathUrl;

    return this.http.get(queryStr, this.getRequestOptionsDownload()).pipe(map(
      (response) => {
        this.downloadFile(response, fileType);
      },
    ), catchError((errorResponse: HttpErrorResponse) => {
          let res: any = errorResponse;
          if (res.outcome.code === '0005' || res.outcome.code === '0007') {
            this.sessionExpired();
          } else {
            return observableThrowError(errorResponse);
          }
        }
    ));
  }

  newObject(orderData: Object, pathUrl: string): Observable<any> {
    // Changed to gcpUrl in order to have newest API implementations on staging BE
    return this.http.post(environment.restBaseUrl + pathUrl, orderData).pipe(
      map((response: Response) => {
        let res: any = response;

        if (res.outcome.success === true) {
          this.logger.log('Service:', res, 300);
          return res;
        } else {
          this.logger.log('List getResult:', ' FAILURE', 200);
          return res;
        }

      }), catchError((error: Response) => {

        let res: any = error;
        if (res.outcome.code === '0005' || res.outcome.code === '0007') {
          this.sessionExpired();
        } else {
          return observableThrowError(error);
        }
      }
    ));
  }

  manageResponse(res) {
    if (res.outcome.success === true) {
      this.totalRows = res.total;
      this.logger.log('Servizio:', 'SUCCESS', 200);
    } else {
      this.logger.log('Servizio:', 'FAILURE', 200);
    }
  }

  manageResponseObject() { }

  manageResponseNotPaging(res) {
    if (res.outcome.success === true) {
     //  this.totalRows = res.total;
      this.logger.log('Servizio:', 'SUCCESS', 200);
    } else {
      this.logger.log('Servizio:', 'FAILURE', 200);
    }
  }

  manageErrors(err): Boolean {
     // GESTIONE CASI DI ERRORE
    if (err.outcome && (err.outcome.code === '0005' || err.outcome.code === '0007')) {
      this.sessionExpired();
      return false;
    }
    return true;
  }

  /** Method that clears the configurations in order to download them again on new login / page refresh */
  clearConfigurations() {
    this.fileConfigurations = null;
    this.partners3rd = null;
  }

  sessionExpired() {
    localStorage.clear();
    this.clearConfigurations();
    this.router.navigate(['\logout\0']);
  }

  getList(queryStr: string, filters?: any) {
    if (filters) {
      queryStr += this.generateQueryFilterString(filters).replace('&', '?');
    }

    return this.http.get(queryStr).pipe(
    map(
      (response: Response) => {
        let res: any = response;
        this.manageResponse(res)
        return res;
      }
    ), catchError(
      (error: Response) => {
        let err: any = error;
        if (this.manageErrors(err)) {
          return observableThrowError(err.json);
        }
      }
    ));
  }

  getGenericFilterResult(idSupplierFilter, idCommissionFilter, idOrderFilter, dateFrom, dateTo, status) {

    let queryStr;

    if (idSupplierFilter !== null) {
      idSupplierFilter = '&idSupplier=' + idSupplierFilter;
    } else {
      idSupplierFilter = '';
    }

    if (idCommissionFilter !== null) {
      idCommissionFilter = '&idCommission=' + idCommissionFilter;
    } else {
      idCommissionFilter = '';
    }

    if (idOrderFilter !== null) {
      idOrderFilter = '&idOrder=' + idOrderFilter;
    } else {
      idOrderFilter = '';
    }

    if (dateFrom !== null) {
      dateFrom = '&dateFrom=' + dateFrom;
    } else {
      dateFrom = '';
    }

    if (dateTo !== null) {
      dateTo = '&dateTo=' + dateTo;
    } else {
      dateTo = '';
    }

    if (status !== null) {
      status = '&status=' + status;
    } else {
      status = '';
    }

    queryStr = environment.restBaseUrl
    + this.restURL
    + '?paging=' + this.paging
    + '&page=' + this.page
    + '&rows=' + this.rows
    + idSupplierFilter
    + idCommissionFilter
    + idOrderFilter
    + dateFrom
    + dateTo
    + status;

    return this.http.get(queryStr).pipe(
      map(
      (response: Response) => {
        let res: any = response;
        if (res.outcome.success === true) {
          this.logger.log('List getResult:', 'SUCCESS', 200);
          return res;
        } else {
          this.logger.log('List getResult:', 'FAILURE', 200);
          return res;
        }
      }
      ),
      catchError(
      (error: Response) => {
        let res: any = error;
        if (res.outcome.code === '0005' || res.outcome.code === '0007') {
          this.sessionExpired();
        } else {
          return observableThrowError(error);
        }
      }
    ));
  }

  getDoc(pathUrl, body, getApi = false) {
    let queryStr = environment.restBaseUrl + pathUrl;
    this.logger.log('body', body, 200);
    return getApi
      ? this.http.get(queryStr, { responseType: 'blob' })
      :  this.http.post(queryStr, body, { responseType: 'blob' });
  }

  getReprintDoc(pathUrl) {
    let queryStr = environment.restBaseUrl + pathUrl;
    return this.http.get(queryStr, { responseType: 'blob' });
  }

  getCsv(pathUrl) {
    let queryStr = environment.restBaseUrl + pathUrl;
    return this.http.get(queryStr, { responseType: 'blob' });
  }

  approve(idDoc: number): Observable<any>  {
    let rev: any = {}

    return this.http.put(environment.restBaseUrl + '/approval/document/approve/' + idDoc, rev).pipe(
      map(
        (response: Response) => {
          let res: any = response;

          if (res.outcome.success === true) {
            this.logger.log('APPROVE DOC', '  SUCCESS', 200);
            return res;
          } else {
            this.logger.log('APPROVE DOC', '  FAILURE', 200);
            return res;
          }
        }
      ),
      catchError(
        (error: Response) => {
          return observableThrowError(error);
        }
      )
    );
  }

  revision(idDoc: number, docNote, revision, kind): Observable<any>  {
    let kindString;
    let revisionKind;
     let rev: any = {
        idDocument: idDoc,
        notes: docNote
      }

      let revisionCheckList: any = {
        idChecklist: idDoc,
        notes: docNote
      }

    if (kind === 'checklist') {

          revisionKind = revisionCheckList
          kindString = 'checklist'
    } else {
      revisionKind = rev
      kindString = 'document'
    }
    
    return this.http.put(environment.restBaseUrl + '/approval/' + kindString + '/' + revision, revisionKind).pipe(
    map(
      (response: Response) => {
        let res: any = response;

        if (res.outcome.success === true) {
          this.logger.log('REVISION DOC', '  SUCCESS', 200);
          return res;
        } else {
          this.logger.log('REVISION DOC', '  FAILURE', 200);
          return res;
        }
      }
    ),
    catchError(
      (error: Response) => {
        return observableThrowError(error);
      }
    ));
  }

  upload(pathUrl, doc: Object): Observable<any> {

    return this.http.post(environment.restBaseUrl + pathUrl, doc).pipe(
      map(
      (response: Response) => {
        let res: any = response;

        if (res.outcome.success === true) {

          this.logger.log('Service:', res, 300);
        } else {
          this.logger.log('Upload ', 'failure', 300);
          return res;

        }
      }
      ),
      catchError(
      (error: Response) => {
        let res: any = error;
        if (res.outcome.code === '0005' || res.outcome.code === '0007') {
          this.sessionExpired();
        } else {
          return observableThrowError(error);
        }
      })
    );
  }

  getRequestOptionsDownload(): any {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      responseType: 'blob'
    };
    return httpOptions;
  }

  /** Method that retrieves informations about the current uploaded controller APP */
  getControllerAppInfo(): Observable<any> {
    const body = {
      serialNumber: 'ya9gz31HrwwK9x8MJ7lDjmIt6ea8RwyL',
      description: 'Backoffice Praticko',
      version: '0.0.0'
    };

    return this.http.post(
      environment.restBaseUrl + '/mobileController',
      body,
      { headers: { 'Authorization': environment.controllerAPIAuthorization } }
    );
  }

  associateVoucherCompany(requestBody: { name: string, idCompany: number }): Observable<any> {
    return this.http.post(environment.restBaseUrl + '/voucherEmitChannel/associate', requestBody);
  }

  // ---------- FILES MANAGEMENT METHODS ---------- //

  generateUploadBody(uploadType: string, fileToUpload: File, base64File: string, idCompany: string, appInfo?: AppInfo): any {
    switch (uploadType) {
      case UploadType.STOPS:
        return { fileEncoded: base64File, idCompany };
      case UploadType.GTFS:
        return { fileName: fileToUpload.name, fileEncoded: base64File, idCompany };
      case UploadType.APK:
        return { fileName: fileToUpload.name, fileEncoded: base64File }
      case UploadType.CONTROLLER_APK:
        return { fileName: fileToUpload.name, version: appInfo.version, forced: appInfo.forced, enabled: appInfo.enabled, fileEncoded: base64File }
    }
  }

  generateFilesUrl(filesType: string): string {
    switch (filesType) {
      case UploadType.GTFS:
      case UploadType.TIMETABLES:
        return '/file/list';
      case UploadType.APK:
        return '/obliterator/apk/apks';
      case UploadType.TEMPLATE:
        return '';
    }
  }

  generateDownloadFileType(filesType: string): string {
    switch (filesType) {
      case UploadType.GTFS:
        return 'TRANSIT';
      case UploadType.TIMETABLES:
        return 'PDF_ORARI';
      case UploadType.APK:
        return 'APK';
      case UploadType.TEMPLATE:
        return 'PDF_ORARI';
    }
  }

  /** Method that manages the download of a file depending on its type */
  downloadFile(response: any, fileType?: string): void {
    let type = '';

    switch (fileType) {
      case UploadType.GTFS:
        type = 'application/zip';
      break;
      case UploadType.APK:
        type = 'application/vnd.android.package-archive';
      break;
      case UploadType.TEMPLATE:
      case UploadType.TIMETABLES:
        type = 'application/pdf';
      break;
      default:
      break;
    }

    const blob = type ? new Blob([response], { type }) : response;
    window.open(window.URL.createObjectURL(blob));
  }

  manageSubmitAction(actionType: string, entity: any, path: string, backUrl: string, entityName: string): void {
    swal2.fire({
      title: 'Operazione in corso...',
      didOpen: function() {
        swal2.showLoading();
      }
    });

    const creation = actionType === SubmitActionType.CREATE;
    this[creation ? 'newObject' : 'edit'](entity, path).subscribe({
      next: (response) => {
        if (response.outcome.success) {
          swal2.fire('Successo', `${creation ? 'Creazione' : 'Modifica'} ${entityName} avvenuta con successo`, 'success');
          this.router.navigate([backUrl]);
        } else {
          swal2.fire('Attenzione', response.outcome.code +  response.outcome.description, 'warning');
        }
      },
      error: (error) => {
        this.logger.log('Error', error, 200);
        swal2.fire(
          'Errore',
          `Spiacente, ${creation ? 'creazione' : 'modifica'} ${entityName} non effettuata. Si prega di riprovare`,
          'error'
        );
      }
    });
  }

  // ---------- PRIVATE METHODS ---------- //

  /** Method that sets query strings required for list filters apis */
  private setListFilterQueryStr(filters?: any, noBaseUrl = false, direction = false, paging = true): string {
    // stringa per chiamata da fare
    let queryStr = '';

    // imposto i parametri del filtro
    if (filters) {
      queryStr = this.generateQueryFilterString(filters);
    }

    // creo tutta la chiamata completa di paginazione e righe
    if (paging) {
      queryStr = (noBaseUrl ? '' : environment.restBaseUrl + this.restURL)
      + '?paging=' + this.paging
      + '&page=' + this.page
      + '&rows=' + this.rows
      + (direction ? '&directionId=0' : '')
      + queryStr;
    } else {
      queryStr = (noBaseUrl ? '' : environment.restBaseUrl + this.restURL)
      + queryStr;
    }

    return queryStr;
  }

  private generateQueryFilterString(filters: any): string {
    let filterString = '';

    Object.keys(filters).forEach((key) => {
      if (filters[key] && filters[key].toString() !== '' && filters[key].toString() !== 'all') {
        filterString = filterString + '&' + key + '=' + filters[key];
      }
    });

    return filterString;
  }

}
