import { Component, OnInit } from '@angular/core';
import { ICompany, IExternal } from '../../../interfaces/configuration.interface';
import { FormControl, FormGroup, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ListService, SubmitActionType } from '../../../services/list.service';
import { LoggingService } from '../../../services/logging.service';
// import swal2 from 'sweetalert2';

@Component({
  selector: 'app-external-manage',
  templateUrl: 'external-manage.component.html',
  styleUrls: ['../../../app.component.css']
})
export class ExternalManageComponent implements OnInit {

  // companies info
  urlCompany = '/company/companies';
  companiesList: ICompany[] = [];

  // testo in HTML per tipo pagina
  kindTitle: string;
  kindManage: number;

  externalPath = '/external';
  dataExternal: IExternal;

  externalForm: UntypedFormGroup = new FormGroup({
    name: new FormControl(null, Validators.required),
    code: new FormControl(null, Validators.required),
    apiKey: new FormControl(null, Validators.required),
    idCompany: new FormControl(null, Validators.required),
    enabled: new FormControl(false)
  });

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private listService: ListService,
    private logger: LoggingService
  ) {
    // Se creo nuova chiave esterna
    if (this.route.snapshot.toString().indexOf('new') !== -1) {
      this.kindTitle = 'Creazione';
      this.kindManage = 1;
    } else { // se modifico chiave esterna
      this.kindTitle = 'Modifica';
      this.kindManage = 0;
      if (localStorage.getItem('dataPassed') === '') {
        this.router.navigate(['/utility/external/list']);
        return;
      }
      this.dataExternal = JSON.parse(localStorage.getItem('dataPassed'));
      this.setFormValues();
      localStorage.setItem('dataPassed', ''); // svuoto dato in localstorage
    }
  }

  ngOnInit(): void {
    this.getCompanies();
  }

  back(): void {
    this.router.navigate(['/utility/external/list']);
  }

  onSubmit(): void {
    const external: IExternal = this.externalForm.value;
    if (!this.kindManage) {
      external.id = this.dataExternal.id;
    }

    this.listService.manageSubmitAction(
      this.kindManage ? SubmitActionType.CREATE : SubmitActionType.EDIT,
      external,
      this.externalPath,
      '/utility/external/list',
      'chiave esterna'
    );
  }

  // --------- PRIVATE METHODS --------- //

  private getCompanies() {
    this.listService.getListSelect(this.urlCompany).subscribe({
      next: (response) => {
        this.companiesList = response.results;
        this.logger.log('Lista aziende', this.companiesList, 300);
      },
      error: (error) => {
        this.logger.log('Error', error, 200);
      }
    });
  }

  private setFormValues(): void {
    this.externalForm.get('name').setValue(this.dataExternal.name);
    this.externalForm.get('code').setValue(this.dataExternal.code);
    this.externalForm.get('apiKey').setValue(this.dataExternal.apiKey);
    this.externalForm.get('idCompany').setValue(this.dataExternal.idCompany);
    this.externalForm.get('enabled').setValue(this.dataExternal.enabled);

    /** Some properties must not be enabled on edit, since they're configured with formControlName, they must be disabled with the specific function */
    this.externalForm.get('code').disable();
    this.externalForm.get('idCompany').disable();
    this.externalForm.get('enabled').disable();
  }

}
