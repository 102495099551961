<div class="wrapper wrapper-content animated fadeInRight">
  <div class="row">
    <div class="col-xs-12 col-sm-10 col-md-8 col-sm-offset-1 col-md-offset-2">
      <div class="ibox float-e-margins">
        <div class="ibox-title">
            <h2>{{kindTitle}} stampante</h2>
        </div>
        <form (ngSubmit)="onSubmit()" #printerForm="ngForm">
          <div class="ibox-content"> 

              <div *ngIf="isSol1" class="form-group form-group col-xs-12 col-sm-6">
                <label for="idCompany">Azienda di trasporto</label>
                <select
                type="text"
                id="idCompany"
                class="form-control"
                [ngModel]="dataPrinter?.idCompany"
                ngModel
                name ="idCompany"
                required
                idCompany
                #idCompany="ngModel"
                idResale
                (change)="companySelected()">
                    <option *ngFor="let r of companiesList" [ngValue]="r.idCompany"> {{r.legalBusinessName}}</option>
                </select>
              </div>
              <div class="form-group col-xs-12 col-sm-6">
                <label for="serialNumber">Serial Number</label>
                <input
                  type="text"
                  id="serialNumber"
                  name="serialNumber"
                  class="form-control"
                  ngModel
                  [ngModel]="dataPrinter?.serialNumber"
                  required
                  serialNumber
                  #serialNumber= "ngModel">
              </div>

              <div *ngIf="kindManage == 0" hidden class="form-group form-group col-md-12 col-sm-6">
                <label for="pcUUID">pcUUID</label>
                <input
                  type="text"
                  id="pcUUID"
                  name="pcUUID"
                  class="form-control"
                  ngModel
                  [ngModel]="dataPrinter?.pcUUID"
                
                  pcUUID
                  #pcUUID= "ngModel">
              </div>


              <div *ngIf="!isSol1" hidden  class="form-group form-group col-md-12 col-sm-6">
                  <label for="idCompany">idCompany</label>
                  <input
                    type="text"
                    id="idCompany"
                    name="idCompany"
                    class="form-control"
                    ngModel
                    [ngModel]="companyValue"
                  
                    idCompany
                    #idCompany= "ngModel">
                </div>
              
              <div class="form-group col-xs-12 col-sm-6">
                <label for="macAddress"> Mac Address</label>
                <input
                  type="text"
                  id="macAddress"
                  class="form-control"
                  ngModel
                  [ngModel]="dataPrinter?.macAddress"
                  name="macAddress"
                  required
                  macAddress
                  #macAddress="ngModel">
              </div>

              <div class="form-group form-group col-xs-12 col-sm-6">
                <label for="model"> Modello</label>
                <input
                  type="text"
                  id="model"
                  class="form-control"
                  ngModel
                    [ngModel]="dataPrinter?.model"
                  name="model"
                  required
                  model
                  #model="ngModel">
              </div>

            
              
              <div class="form-group form-group col-xs-12 col-sm-6">
                <label for="name"> Nome</label>
                <input
                  type="text"
                  id="name"
                  class="form-control"
                  ngModel
                  [ngModel]="dataPrinter?.name"
                  name="name"
                  required
            
                  #name="ngModel">
              </div>

         

              <div class ="form-group form-group col-xs-12 col-sm-6">
                <label for="dateSell">Data di vendita</label>
                  <input type="text"
                    class="form-control"
                    name="dateSell"
                    id="dateSell"
                    [ngModel]="dataPrinter?.dateSell"
                    required
                    bsDatepicker
                    #bsDatepicker
                    placement="bottom"
                    autocomplete="off"
                    [bsConfig]="bsConfig" />
              </div>

            <div  class="form-group col-sm-6">
              <label for="purpose">Utilizzo</label>
              <select  type="text" id="purpose" class="form-control" [ngModel]="dataPrinter?.purpose" ngModel name="purpose" required purpose #purpose="ngModel" required>
                <option value="resale">Rivendita</option>
                <option value="desk">Desk</option>
              </select>
            </div>  

            <div  *ngIf="purpose.value == 'resale'" class="form-group form-group col-xs-12 col-sm-6">
              <label for="idResale">Rivendita</label>
              <select
                type="text"
                id="idResale"
                class="form-control"
                [ngModel]="dataPrinter?.idResale"
                ngModel
                name ="idResale"
      
                idResale
                #idResale="ngModel"
                idResale
              >
                <option  [ngValue]=null> --- </option>
                <option *ngFor="let r of resalesList.row" [ngValue]="r.id"> {{r.legalBusinessName}}</option>
              </select>
            </div>

            <div  *ngIf="purpose.value == 'desk'" class="form-group form-group col-xs-12 col-sm-6">
              <label for="idDesk">Desk</label>
              <select
                type="text"
                id="idDesk"
                class="form-control"
                [ngModel]="dataPrinter?.idDesk"
                ngModel
                name ="idDesk"
        
                idDesk
                #idDesk="ngModel"
                idDesk
              >
                <option  [ngValue]=null> --- </option>
                <option *ngFor="let d of desksList.row" [ngValue]="d.id"> {{d.name}}</option>
              </select>
            </div> 
            
              <div class="clearfix"></div>
          </div>
          <div class="ibox-footer">

            <button class="btn btn-default" type="button"  (click)="back()">Annulla</button>
            <button class="btn btn-redBU" type="submit" style="float: right"  [disabled]="!printerForm.valid" >Salva </button>
           
          </div>
        </form>
      </div>
    </div>
  </div>
</div>    