import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LoggingService } from '../../../services/logging.service';
import { ListService, SubmitActionType } from '../../../services/list.service';
import { AuthService } from '../../../services/auth.service';
import { UtilsService } from '../../../services/utils.service';

@Component({
  selector: 'app-zones-manage',
  templateUrl: './zones-manage.component.html',
  styleUrls: ['../../../app.component.css']
})
export class ZonesManageComponent implements OnInit {
  // form html
  @ViewChild('zoneForm') formZone: NgForm;
  
  // testo in HTML per tipo pagina
  kindTitle;
  kindManage;
  // URL servizio da chiamare, se post o put dipenderà da dove arriviamo
  serviceToCallPath = '/zone';
  // oggetto contenente dati component
  dataZone: ZoneModel = new ZoneModel();
  companiesList: any[] = new Array();
  pathToCallCompaniesPath = '/company/companies';

  urlSpecificResalePath = '/user/';
  isSoluzione1;
  typeUser;

  errorMessage;
  id;
  idCompany;

  constructor(
    private utils: UtilsService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private logger: LoggingService,
    public listService: ListService,
    private router: Router
  ) {
    // Se creo nuovo veicolo
    if (route.snapshot.toString().indexOf('new') !== -1) {
      this.kindTitle = 'Nuovo';
      this.kindManage = 1;
    } else {
      // se modifico veicolo
      this.kindTitle = 'Modifica';
      this.kindManage = 0;
      if (localStorage.getItem('dataPassed') === '') {
        this.router.navigate(['/zones/list']);
        return;
      }
      this.dataZone = JSON.parse(localStorage.getItem('dataPassed'));
      console.log('OGGETTO ARRIVATO', this.dataZone);
      // TO DO DA ELIMINARE

      this.id = this.dataZone.id;
      this.idCompany = 1;

      localStorage.setItem('dataPassed', ''); // svuoto dato in localstorage
    }
  }

  ngOnInit() {
    this.listService.resetList();
    this.listService.backToList = true;
    this.listService.listRouting = '/zones/list';
    console.log(this.dataZone);
    this.getCompanies();
    this.typeUser = this.utils.getTypeUser();
    this.isSoluzione1 = this.utils.isSol1(this.typeUser);
  }

  getCompanies() {
    this.listService.getListSelect(this.pathToCallCompaniesPath).subscribe(
      response => {
        this.companiesList = response.results;
        this.logger.log('Lista rivenditori', this.companiesList, 300);
        // console.log('LISTA commesse: ',response.results);
      },
      error => {
        this.logger.log('Error', error, 200);
      }
    );
  }

  onSubmit(): void {
    this.dataZone = this.formZone.value;
    if (this.kindManage) {
      this.dataZone['zoneId'] = this.dataZone.id;
      delete this.dataZone.id;

      if (!this.isSoluzione1) {
        this.dataZone['idCompany'] = localStorage.getItem('idCompany');
      }
    } else {
      this.dataZone.idCompany = this.formZone.value.idCompany;
    }

    this.listService.manageSubmitAction(
      this.kindManage ? SubmitActionType.CREATE : SubmitActionType.EDIT,
      this.dataZone,
      this.serviceToCallPath,
      '/zones/list',
      'zona'
    );
  }

  back() {
    this.router.navigate(['/zones/list']);
  }
}

export class ZoneModel {
  id;
  idCompany;
  zoneId;
  description;
  constructor() { }
}
