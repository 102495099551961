<div class="col-sm-8 col-sm-offset-2">

  <div class="wrapper wrapper-content animated fadeInRight">
    <div class="row">

      <div class="ibox float-e-margins">
        <form (ngSubmit)="onSubmit()" #companiesForm="ngForm">

          <div class="ibox-title col-md-12 ">

            <h2 class="col-xs-5">{{kindTitle}} Azienda di trasporto</h2>

          </div>

          <div class="ibox float-e-margins">
            <div class="ibox-content col-md-12">

              <ul class="nav nav-tabs">

                <li class="{{isActiveTabsLegal}}">
                  <a data-toggle="tab"  (click)="viewLegal()" aria-expanded="false">Sede legale</a>
                </li>
                <li class="{{isActiveTabsDetails}}">
                  <a data-toggle="tab" (click)="viewDetails()"  aria-expanded="false">Dettagli</a>
                </li>
              </ul>


              <div class="tab-content">
                <div id="tab-4" class="{{isActiveLegal}}">
                  <div style="padding-left: 0px" class="panel-body">
                    <div class="form-group col-md-6 col-sm-4">
                      <label for="legalBusinessName">Ragione sociale</label>
                      <input type="text" id="legalBusinessName" name="legalBusinessName" [ngModel]="dataCompany?.legalBusinessName" class="form-control"
                        ngModel required legalBusinessName #legalBusinessName="ngModel" maxlength="80">
                    </div>
                    <div class="form-group col-md-6  col-sm-4">
                      <label for="vatNumber">Partita iva</label>
                      <input type="text" id="vatNumber" [ngModel]="dataCompany?.vatNumber" name="vatNumber" class="form-control" ngModel required
                        vatNumber #vatNumber="ngModel">
                    </div>
                    <div class="form-group col-md-6 col-sm-4">
                      <label for="legalAddress"> Indirizzo</label>
                      <input type="text" id="legalAddress" class="form-control" ngModel name="legalAddress" [ngModel]="dataCompany?.legalAddress"
                        required legalAddress #legalAddress="ngModel">
                    </div>
                    <div class="form-group col-md-4 col-sm-4">
                      <label for="legalCity"> Città</label>
                      <input type="text" id="legalCity" class="form-control" ngModel [ngModel]="dataCompany?.legalCity" name="legalCity" required
                        legalCity #legalCity="ngModel">
                    </div>
                    <div class="form-group col-md-2 col-sm-4">
                      <label for="legalProv">Provincia</label>
                      <input type="text" id="legalProv" name="legalProv" class="form-control" [ngModel]="dataCompany?.legalProv" ngModel required
                        legalProv #legalProv="ngModel">
                    </div>
                    <div class="form-group col-md-4 col-sm-4">
                      <label for="legalZipCode">Cap</label>
                      <input type="text" id="legalZipCode" name="legalZipCode" [ngModel]="dataCompany?.legalZipCode" class="form-control" ngModel
                        required legalZipCode #legalZipCode="ngModel">
                    </div>


                    <div class="form-group col-xs-0 col-md-2 col-sm-0">
                      </div>


                    <div class="form-group col-md-6 col-sm-6">
                      <label for="legalZipCode">Descrizione Biglietto</label>
                      <input type="text" id="descriptionPrintTicket" name="descriptionPrintTicket" [ngModel]="dataCompany?.descriptionPrintTicket" class="form-control" ngModel
                        required descriptionPrintTicket #descriptionPrintTicket="ngModel">
                    </div>

                  </div>
                </div>

                <div id="tab-5" class="{{isActiveDetails}}">
                    <div style="padding-left: 0px" class="panel-body">
                      
                        <div class="form-group col-md-4 col-sm-4">
                            <label for="rif"> Persona</label>
                            <input type="text" id="rif" class="form-control" ngModel name="rif" [ngModel]="dataCompany?.rif" required rif #rif="ngModel">
                          </div>
                          <div class="form-group col-md-4 col-sm-4">
                            <label for="cell"> Cellulare</label>
                            <input type="text" id="cell" [ngModel]="dataCompany?.cell" class="form-control" ngModel name="cell" required cell #cell="ngModel">
                          </div>
                          <div class="form-group col-md-4 col-sm-4">
                            <label for="status">Stato </label>
                            <select [disabled]="companiesForm.value.status === 'ADMINISTRATOR'" type="text" id="status" class="form-control" ngModel
                              [ngModel]="dataCompany?.status" name="status" required status #status="ngModel" required>
                              <option [ngValue]="'1'">Attiva</option>
                              <option [ngValue]="'0'">Non Attiva</option>
                            </select>
                          </div>
        
                          <div class="form-group col-md-6 col-sm-4">
                            <label for="latitude">Latitudine</label>
                            <input type="text" id="latitude" name="latitude" [ngModel]="dataCompany?.latitude" class="form-control" ngModel required
                              latitude #latitude="ngModel" [pattern]="latRegex">
                          </div>
                          <div class="form-group col-md-6 col-sm-4">
                            <label for="longitude"> Longitudine</label>
                            <input type="text" id="longitude" class="form-control" ngModel [ngModel]="dataCompany?.longitude" name="longitude" required
                              longitude #longitude="ngModel" [pattern]="lngRegex">
                          </div>
                          <div class="form-group col-md-6 col-sm-4">
                            <label for="phone1"> Telefono 1</label>
                            <input type="text" id="phone1" [ngModel]="dataCompany?.phone1" class="form-control" ngModel name="phone1" required phone1
                              #phone1="ngModel">
                          </div>
                          <div class="form-group col-md-6 col-sm-4">
                            <label for="phone2"> Telefono 2</label>
                            <input type="text" id="phone2" class="form-control" [ngModel]="dataCompany?.phone2" ngModel name="phone2" phone2 #phone2="ngModel">
                          </div>
        
                          <div *ngIf="kindManage == 0" hidden class="form-group col-md-6">
                            <label for="idCompany"> EMail 1</label>
                            <input type="text" id="idCompany" [ngModel]="dataCompany?.idCompany" class="form-control" ngModel name="idCompany" required
                              idCompany #idCompany="ngModel">
                          </div>
                          <div class="form-group col-md-6 col-sm-4">
                            <label for="email1"> EMail 1</label>
                            <input type="text" id="email1" [ngModel]="dataCompany?.email1" class="form-control" ngModel name="email1" required email1
                              #email1="ngModel">
                          </div>
                          <div class="form-group col-md-6 col-sm-4">
                            <label for="email2"> EMail 2</label>
                            <input type="text" id="email2" [ngModel]="dataCompany?.email2" class="form-control" ngModel name="email2" email2 #email2="ngModel">
                          </div>
  
                    </div>
                  </div>
                </div>
            </div>
            <div class="ibox-footer">
              <br />
              <br />
              <button style="margin-top: 10px" class="btn btn-default" type="button" (click)="back()">Annulla</button>
              <button class="btn btn-redBU" type="submit" style="float: right;  margin-top: 10px;" [disabled]="!companiesForm.valid">Salva</button>
            </div>
          </div>
        </form>
      </div>