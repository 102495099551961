import { Component, OnInit, ViewChild } from "@angular/core";
import {
  FormArray,
  FormControl,
  FormGroup,
  Validators,
  NgForm
} from "@angular/forms";

import { Observable } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import { LoggingService } from "../../../services/logging.service";
import { ListService, SubmitActionType } from "../../../services/list.service";
import { AuthService } from "../../../services/auth.service";
import swal2 from "sweetalert2";
import { UtilsService } from "../../../services/utils.service";

@Component({
  selector: "app-blocks-manage",
  templateUrl: "./blocks-manage.component.html",
  styleUrls: ["../../../app.component.css"]
})
export class BlocksManageComponent implements OnInit {
  //testo in HTML per tipo pagina
  kindTitle;
  kindManage;
  //URL servizio da chiamare, se post o put dipenderà da dove arriviamo
  serviceToCallPath = "/block";
  //form html
  @ViewChild("blockForm") formBlock: NgForm;
  //oggetto contenente dati component
  dataBlock: BlockModel = new BlockModel();
  companiesList: any[] = new Array();
  pathToCallCompaniesPath = "/company/companies";

  urlSpecificResalePath = "/user/";
  isSoluzione1;
  typeUser;

  errorMessage;
  id;
  idCompany;

  constructor(
    private utils: UtilsService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private logger: LoggingService,
    public listService: ListService,
    private router: Router
  ) {
    //Se creo nuovo veicolo
    if (route.snapshot.toString().indexOf("new") != -1) {
      this.kindTitle = "Nuovo";
      this.kindManage = 1;
    } else {
      //se modifico veicolo
      this.kindTitle = "Modifica";
      this.kindManage = 0;
      if (localStorage.getItem("dataPassed") == "") {
        this.router.navigate(["/blocks/list"]);
        return;
      }
      this.dataBlock = JSON.parse(localStorage.getItem("dataPassed"));
      logger.log("OGGETTO ARRIVATO", this.dataBlock, 200);
      //TO DO DA ELIMINARE

      this.id = this.dataBlock?.id;

      this.idCompany = 1;

      localStorage.setItem("dataPassed", ""); //svuoto dato in localstorage
    }
  }

  ngOnInit() {
    this.listService.resetList();
    this.listService.backToList = true;
    this.listService.listRouting = "/blocks/list";
    this.getCompanies();
    this.typeUser = this.utils.getTypeUser();
    this.isSoluzione1 = this.utils.isSol1(this.typeUser);
  }

  getCompanies() {
    this.listService.getListSelect(this.pathToCallCompaniesPath).subscribe(
      response => {
        this.companiesList = response.results;
        this.logger.log("Lista rivenditori", this.companiesList, 300);
      },
      error => {
        this.logger.log("Error", error, 200);
      }
    );
  }

  onSubmit(): void {
    this.dataBlock = this.formBlock.value;
    if (!this.kindManage) {
      this.dataBlock.id = this.id;
      this.dataBlock["status"]  = "1"
      this.dataBlock.idCompany = this.formBlock.value.idCompany;
    }

    this.listService.manageSubmitAction(
      this.kindManage ? SubmitActionType.CREATE : SubmitActionType.EDIT,
      this.dataBlock,
      this.serviceToCallPath,
      '/blocks/list',
      'blocco'
    );
  }

  back() {
    this.router.navigate(["/blocks/list"]);
  }
}

export class BlockModel {
  id;
  idCompany;
  id_block;
  description;
  status;
  constructor() {}
}
