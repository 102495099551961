import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ICustomer, ICustomerValidationTypology, IPass, IValidation, IValidationCreation } from '../../../../interfaces/configuration.interface';
import { ListService } from '../../../../services/list.service';
import { UtilsService } from '../../../../services/utils.service';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import swal2 from 'sweetalert2';

@Component({
  selector: 'app-users-validations-manage',
  templateUrl: './users-validations-manage.component.html',
  styleUrls: ['../../../../app.component.css']
})
export class UsersValidationsManageComponent implements OnInit {

  @ViewChild('validationForm') validationForm: NgForm;

  kindTitle: string;
  kindManage: number;
  existingValidation: IValidation;
  dataValidation: IValidationCreation = {};
  customerData: ICustomer = {};
  customerEmail: string;
  typeUser: boolean;
  thirdPartyUser: boolean = false;
  colorTheme = "theme-orange";
  bsConfig: Partial<BsDatepickerConfig>;
  numOfFiles: number = 1;
  uploadedFiles: File[] = [];
  fileDescriptions: string[] = [];
  excessiveFiles: number[] = [];

  urlPassesPath = '/pass?paginated=false&notAssociated=true';
  urlCustomerValidationTypologies = '/customer/validation/typologies';
  urlCustomerValidationCreate = '/customer/validation/create';

  dataPasses: IPass[] = [];
  dataValidationTypologies: ICustomerValidationTypology[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private utils: UtilsService,
    private listService: ListService
  ) {
    this.typeUser = this.utils.getTypeUser();
    this.bsConfig = Object.assign(
      {},
      { containerClass: this.colorTheme },
      { dateInputFormat: "DD-MM-YYYY" },
      { rangeInputFormat: "DD-MM-YYYY" },
      { maxDate: new Date() }
    );

    if (this.route.snapshot.toString().indexOf('new') !== -1) {
      this.kindTitle = 'Nuovo';
      this.kindManage = 1;
      this.managePassedValues();
    } else {
      this.kindTitle = 'Modifica';
      this.kindManage = 0;
      this.existingValidation = JSON.parse(localStorage.getItem('dataPassed'));
      localStorage.removeItem('dataPassed');
      console.warn('>>>>>>EXISTING VALIDATION: ', this.existingValidation);
    }
  }

  async ngOnInit(): Promise<void> {
    await this.companySelectsFill();
  }

  returnToList(): void {
    this.router.navigate(['/users/validations']);
  }

  handleFilesInput(file: File, idx: number): void {
    if (file.size > 5000000) {
      this.excessiveFiles.push(idx);
    } else {
      this.excessiveFiles.splice(this.excessiveFiles.indexOf(idx), 1);
      this.uploadedFiles.push(file);
    }
  }

  onSubmit(): void {
    swal2.fire({title: 'Operazione in corso...', didOpen: function () {swal2.showLoading()}});

    let requestData: IValidationCreation = this.validationForm.value;

    if (this.customerData) {
      requestData.idCustomer = this.customerData?.idCustomer;
    }

    const formData = new FormData();
    Object.entries(requestData).forEach(([key, value]) => {
      value ? formData.append(key, (key === 'birthDate' ? this.utils.formatDate(value) : value)) : null;
    });

    /** A FormData has to be created, valorized with values and with the files stored in the uploadedFiles array */
    this.uploadedFiles.forEach((file, i) => {
      formData.append('file', file, file.name);
      formData.append('fileDescription', this.fileDescriptions[i]);
    });

    this.listService.newObject(formData, this.urlCustomerValidationCreate).subscribe({
      next: response => {
        if (response.outcome.success) {
          swal2.fire('Nuova validazione creata correttamente', '', 'success');
          this.returnToList();
        } else {
          const errorMessage =  response.outcome.code +  response.outcome.description
          swal2.fire('Attenzione', errorMessage, 'warning');
        }
      },
      error: _ => {
        swal2.fire('Errore', 'Spiacente, si è verificato un errore tecnico.', 'error');
      }
    });
  }

  changeFileQty(action: number, idx?: number): void {
    if (action === 1) {
      this.numOfFiles++;
    } else {
      this.numOfFiles--;
      this.uploadedFiles.splice(idx, 1);
      this.fileDescriptions.splice(idx, 1);
      this.excessiveFiles.splice(this.excessiveFiles.indexOf(idx), 1);
    }
  }

  // ---------- PRIVATE METHODS --------- //

  private managePassedValues(): void {
    this.customerEmail = localStorage.getItem('customerEmail');
    localStorage.removeItem('customerEmail');

    this.customerData = JSON.parse(localStorage.getItem('customerData'));
    localStorage.removeItem('customerData');

    this.manageCustomerData();
  }

  private manageCustomerData(): void {
    /** If customerData is available then loop through each of its properties and set the corresponding values in dataValidation */
    if (this.customerData) {
      Object.entries(this.customerData).forEach(([key, value]) => {
        if (value) {
          key === 'birthDate' ? this.dataValidation[key] = new Date(value) : this.dataValidation[key] = value;
        }
      });
    } else if (this.customerEmail) {
      this.dataValidation.email = this.customerEmail;
    }
  }

  private async companySelectsFill(filterByCompany: boolean = false): Promise<void> {
    this.dataPasses = await this.utils.getSelectValues(filterByCompany, this.urlPassesPath);
    this.dataValidationTypologies = await this.utils.getSelectValues(filterByCompany, this.urlCustomerValidationTypologies);

    /** Opened in the list, in order to avoid flashes or funny stuff */
    swal2.close();
  }

}
